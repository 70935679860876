<template>
  <div class="relative w-[292px]">
    <button
      aria-label="accesses"
      class="w-[292px] relative z-[1] border border-[#E2E2E2] rounded-lg group peer focus:!rounded-b-none
            [transition:border-radius,_.3s] delay-200 focus:delay-0 cursor-pointer py-[10px] px-[14px] flex
            items-center justify-between outline-none border-none"
    >
      <div class="flex items-center gap-[8px]">
        <i
          class="icon k-global text-[24px] text-[#292D32]"
          :class="[currentAccess.icon]"
        />
        <span>{{ currentAccess.label }}</span>
      </div>
      <span class="icon k-arrow-down-1 text-[24px] group-focus:rotate-180 transition-transform duration-300 delay-200 group-focus:delay-0" />
    </button>
    <div
      style="border-top: 0 !important; border-top-left-radius: 0 !important; border-top-right-radius: 0 !important;"
      class="absolute top-[45px] opacity-0 peer-focus:opacity-100 transition-opacity duration-300 select-none delay-200
              peer-focus:delay-0 pointer-events-none peer-focus:pointer-events-auto hover:pointer-events-auto z-[1]
              peer-focus:z-[2] hover:z-[2] bg-white pb-[10px] px-[14px] border border-[#E2E2E2] rounded-lg"
    >
      <div class="border-t border-[#E2E2E2] pt-[16px] flex flex-col gap-[8px]">
        <b class="font-semibold block mb-[4px]">Siapa saja yang bisa melihat berita ini?</b>
        <label
          v-for="option, i in accessOption"
          :key="i"
          :for="option.value"
          aria-label="access"
          class="flex justify-between items-center cursor-pointer group py-[4px] group"
        >
          <input
            :id="option.value"
            :value="option.value"
            :checked="access == option.value"
            type="radio"
            name="access"
            class="hidden"
            @change="$emit('update:access', option.value)"
          >
          <div class="flex items-center gap-[8px]">
            <i
              class="icon text-[24px] text-[#292D32]"
              :class="[option.icon]"
            />
            <span class="text-[16px] text-[#333333] group-hover:tracking-wider group-active:tracking-tight [transition:letter-spacing,_.3s]">{{ option.label }}</span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            version="1.1"
            width="20"
            height="20"
            viewBox="-20 -20 402 402"
          >
            <g>
              <path
                fill="#F95031"
                d="M0 181c0,-100 81,-181 181,-181 101,0 182,81 182,181 0,101 -81,182 -182,182 -100,0 -181,-81 -181,-182l0 0z"
              />
              <path
                fill="white"
                d="M329 181c0,-81 -66,-147 -148,-147 -81,0 -147,66 -147,147 0,82 66,148 147,148 82,0 148,-66 148,-148z"
              />
              <path
                :fill="access == option.value ? '#F95031' : 'white'"
                d="M181 62c66,0 119,54 119,119 0,66 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 54,-119 119,-119z"
              />
            </g>
          </svg>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    access: {
      type: String,
      default: '',
    },
    accessOption: {
      type: Array,
      default: () => [
        { value: 'public', label: 'Public', icon: 'k-global' },
        { value: 'partner', label: 'Partner Terasign', icon: 'k-people' },
      ],
    },
  },
  computed: {
    currentAccess() {
      return this.accessOption.find(a => a.value === this.access)
    },
  },
}
</script>
