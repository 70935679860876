<template>
  <section style="transition: all .5s ease;">
    <div class="modal d-flex justify-content-end">
      <div class="bg-white text-end w-50">
        <div class="d-flex align-items-center p-1 border-b">
          <strong class="my-0 text-dark text-10">Tambah partisipan</strong>
          <span class="ml-auto k-close-circle cursor-pointer text-10" @click="$emit('close')"></span>
        </div>
        <BOverlay
          id="scroll"
          :show="loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <div class="d-flex gap-3 align-items-center p-1">
            <div class="w-100 gap-15" :class="hideCheckbox? 'd-none' : 'd-flex'">
              <b-form-checkbox
                v-model="filterSelected"
                name="filter"
                value="registered"
                @change="getListTalent()"
              >
                Register
              </b-form-checkbox>
              <b-form-checkbox
                v-model="filterSelected"
                name="filter"
                value="selected"
                @change="getListTalent()"
              >
                Select
              </b-form-checkbox>
              <b-form-checkbox
                v-model="filterSelected"
                name="filter"
                value="hired"
                @change="getListTalent()"
              >
                Hired
              </b-form-checkbox>
              <b-form-checkbox
                v-model="filterSelected"
                name="filter"
                value="non job"
                @change="getListTalent()"
              >
                Non-job
              </b-form-checkbox>
            </div>
            <div class="d-flex rounded-lg align-items-center border ml-auto"
              :class="hideCheckbox? 'w-100' : ''"
              :style="hideCheckbox? '' : 'width: 100px'"
            >
              <span
                class="k-search-normal-1 h-100 font-bold text-10 pt-[0px] align-middle mx-[5px]"
                :class="hideCheckbox? 'd-block' : 'd-none'"
              />
              <BFormInput
                v-model="keyword"
                placeholder="Cari"
                @input="handleSearch()"
                @focus="hideCheckbox = true"
                @blur="hideCheckbox = false"
              />
            </div>
            <BButton
              variant="primary"
              class="px-1"
              @click="submit()"
              :disabled="loadingSubmit || talentSelected.length === 0"
            >
              <strong>Tambah</strong>
            </BButton>
          </div>
          <div id="table" style="height: calc(100vh - 150px); overflow-y: scroll;">
            <BTable
              :fields="fields"
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              show-empty
              :items="talents"
            >
              <template #head(checkbox)>
                <b-form-checkbox @change="checkedAll()" v-model="selectAll" />
              </template>
              <template #cell(checkbox)="data">
                <b-checkbox
                  v-model="talentSelected"
                  :value="data.item.id"
                  @change="handleCheckbox()"
                />
              </template>
              <template #cell(name)="data">
                <div class="flex items-center">
                  <img
                    v-if="data.item.photo_profile_url === ''"
                    src="https://storage.googleapis.com/komerce/assets/avatars/image-null.png"
                    alt="Komerce"
                    class="w-[40px] h-[40px] rounded-circle"
                  >
                  <img
                    v-else
                    :src="data.item.photo_profile_url"
                    onerror="this.onerror=null;this.src='https://storage.googleapis.com/komerce/assets/avatars/image-null.png';"
                    alt="Komerce"
                    class="w-[40px] h-[40px] rounded-circle object-fit-cover"
                  >
                  <div class="ml-1">
                    {{ data.item.full_name }}
                  </div>
                </div>
              </template>
              <template #cell(status)="data">
                <span class="registered" v-if="data.item.status === 'registered'"
                  >Registered</span
                >
                <span class="hired" v-if="data.item.status === 'hired'">Hired</span>
                <span class="selected" v-if="data.item.status === 'selected'"
                  >Selected</span
                >
                <span class="non-job" v-if="data.item.status === 'non job'"
                  >Non-Job</span
                >
              </template>
              <template #cell(skill_role)="data">
                <div class="flex items-center">
                  <div class="text-capitalize">
                    {{ data.item.skill_name }}
                  </div>
                </div>
              </template>
            </BTable>
          </div>
        </BOverlay>
      </div>
    </div>
    <div class="modal-backdrop"></div>
  </section>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { tableAddParticipant } from './config'

export default {
  props: {
    idItem: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      hideCheckbox: false,
      fields: tableAddParticipant,
      filterSelected: [],
      status: [],
      keyword: '',
      talents: [],
      selectAll: false,
      talentSelected: [],
      alertError,
      alertSuccess,
    }
  },
  beforeMount() {
    this.getListTalent()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        training_program_id: parseInt(this.$route.params.id, 10),
        talent_id: this.talentSelected.join(','),
      }

      const url = 'v1/training_programs/participants/store'
      await komtimAxiosIns
        .post(url, data)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$emit('close')
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    async getListTalent() {
      this.loading = true
      this.offset = 0
      const params = `status=${this.filterSelected.join(',')}&keyword=${this.keyword}&limit=50`
      const url = `v1/training_programs/${this.$route.params.id}/available_talents?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.talents = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    checkedAll() {
      if (this.selectAll) {
        this.talentSelected = this.talents.map(item => item.id)
      } else {
        this.talentSelected = []
      }
    },
    handleCheckbox() {
      if (this.talentSelected.length === this.talents.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListTalent()
    }, 1000),
  },
}
</script>

<style lang="scss" scoped>
@import './style/Index.scss';
</style>
