<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="border p-[16px] sm:pr-0 rounded-xl mt-[16px] min-h-[268px] flex items-center gap-[24px] flex-col md:flex-row"
  >
    <label
      for="news-uploader"
      aria-label="news-uploader"
      class="w-full max-w-[327px] sm:min-w-[327px] sm:mt-[4px] mb-0 h-[236px] rounded overflow-hidden relative cursor-pointer group bg-[#E2E2E2]"
      :class="{'animate-pulse': loading.all}"
    >
      <input
        v-if="!loading.all"
        id="news-uploader"
        type="file"
        name="news-uploader"
        accept=".jpg, .png"
        class="hidden"
        :disabled="loading.image"
        @click="({ target }) => target.value = null"
        @change="e => $emit('updateImage', e)"
      >
      <img
        v-if="!loading.all"
        :src="meta.image"
        alt="news-image"
        width="327"
        height="236"
        class="w-full h-full object-cover"
      >
      <div
        v-if="!loading.all"
        :class="loading.image ? 'opacity-100' : 'opacity-0'"
        class="bg-[#F4F4F4]/40 grid place-items-center absolute inset-0 p-[32px] group-hover:opacity-100 transition-opacity duration-500"
      >
        <div
          class="text-[#333333] bg-white bg-white flex gap-[8px] items-center justify-center px-[14px] h-[40px] border border-[#E2E2E2]
          rounded-[20px] w-full group-active:scale-95 transition-transform will-change-transform duration-300"
        >
          <b-spinner
            v-if="loading.image"
            small
          />
          <i
            v-else
            class="icon k-edit text-[24px]"
          />
          <span class="text-[14px] font-semibold">Upload Gambar</span>
        </div>
      </div>
    </label>
    <div
      v-if="loading.all || loading.body"
      class="w-full h-[236px] flex flex-col gap-[16px] justify-between"
    >
      <div class="flex flex-col gap-[16px]">
        <div class="h-[30px] rounded-[15px] w-full bg-[#E2E2E2] animate-pulse" />
        <div class="flex flex-col gap-[6px] [&>*]:bg-[#E2E2E2] [&>*]:animate-pulse [&>*]:h-[20px] [&>*]:rounded-[12px] [&>*]:w-full">
          <div
            v-for="i, idx in ['', 'max-w-[240px]', 'max-w-[480px]', 'max-w-[120px]']"
            :key="idx"
            :class="i"
          />
        </div>
      </div>
      <div class="flex items-center justify-between gap-[8px] flex-wrap">
        <div class="h-[30px] rounded-[15px] w-full max-w-[360px] bg-[#E2E2E2] animate-pulse" />
        <div class="flex justify-end grow">
          <div class="h-[40px] rounded-lg w-[192px] bg-[#E2E2E2] animate-pulse" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full pt-[4px] overflow-hidden flex flex-col justify-between min-h-[236px] pr-[16px]"
    >
      <div>
        <div class="flex items-center justify-between gap-[8px] mb-[8px]">
          <div class="flex items-center gap-[8px] w-[calc(100%-40px)]">
            <strong
              v-b-tooltip.hover.top="preview.title"
              class="text-[16px] sm:text-[20px] md:text-[24px] font-semibold w-fit text-[#333333] truncate"
            >{{ preview.title }}</strong>
            <mark
              class="h-[27px] rounded px-[10px] flex items-center text-[12px] sm:text-[14px] font-medium"
              :class="meta.published_at ? 'bg-[#DCF3EB] text-[#34A770]' : 'text-[#626262] bg-[#E2E2E2]'"
            >{{ meta.published_at ? 'Published' : 'Draft' }}</mark>
          </div>
          <div class="relative">
            <button
              aria-label="options"
              class="peer rotate-90 px-[6px] rounded outline-none hover:bg-[#F4F4F4] transition-colors duration-300 grid place-items-center"
            >
              <i class="icon k-more text-[24px]" />
            </button>
            <menu
              class="absolute top-[32px] right-[4px] bg-white border border-[#E2E2E2] shadow-[0_0_10px_4px] shadow-black/5 p-[8px]
              rounded flex flex-col gap-[8px] [&>*]:px-[6px] [&>*]:py-[4px] [&>*]:rounded [&>*]:transition-colors [&>*]:duration-300
              [&>*]:cursor-pointer [&>*]:flex [&>*]:items-center [&>*]:gap-[12px] hover:[&>*]:bg-[#F4F4F4] active:[&>*]:bg-[#F1F2F6]
              [&_i]:text-[24px] [&_i]:text-[#F95031] [&_span]:whitespace-nowrap [&_span]:font-semibold opacity-0 peer-focus:opacity-100
              transition-opacity duration-300 delay-100 peer-focus:delay-0 select-none pointer-events-none peer-focus:pointer-events-auto
              hover:pointer-events-auto"
            >
              <li @click="$emit('openEditor')">
                <i class="icon k-edit" />
                <span>Edit Berita</span>
              </li>
              <li @click="$emit('openResetModal')">
                <i class="icon k-trash" />
                <span>Reset Berita</span>
              </li>
            </menu>
          </div>
        </div>
        <span
          v-if="meta.published_at"
          class="text-[14px] sm:text-[16px]"
        >{{ DAY_MONTH_YEAR(meta.published_at) }}</span>
        <div
          class="my-[12px] h-[84px] sm:h-[98px] overflow-hidden [&>*]:text-[14px] sm:[&>*]:text-[16px] [&_strong]:font-semibold [&>*]:text-ellipsis [&>*]:leading-[21px] sm:[&>*]:leading-[24px] [&>*]:mb-0"
          style="display: -webkit-box; -webkit-line-clamp: 4; line-clamp: 4; -webkit-box-orient: vertical;"
          v-html="preview.body"
        />
      </div>
      <div class="flex items-center justify-between gap-x-[8px] gap-y-[16px] flex-wrap">
        <div
          v-if="!meta.published_at"
          class="bg-[#FFF2E2] text-[#AF6A13] flex items-center lg:h-[30px] px-[16px] py-[4px] gap-[10px] rounded-[15px]"
        >
          <i class="icon k-clock-1 text-[18px] sm:text-[20px]" />
          <span class="text-[12px] sm:text-[14px]">Akan diunggah otomatis pada <b class="font-semibold">{{
            DAY_MONTH_YEAR(new Date(meta.updated_at).setDate(new Date(meta.updated_at).getDate() + 7))
          }}</b></span>
        </div>
        <div class="flex justify-end grow">
          <button
            aria-label="publish"
            class="h-[40px] w-[192px] px-[16px] flex items-center justify-center gap-[8px] outline-none !border-none group"
            :class="isDisable ? 'bg-[#F4F4F4] text-[#C2C2C2] rounded-lg' : 'button-primary'"
            :disabled="isDisable"
            @click="$bvModal.show('publish-news-modal')"
          >
            <span
              class="text-[14px] group-hover:tracking-wider group-active:tracking-tight group-disabled:!tracking-normal
            [transition:letter-spacing,_.3s] whitespace-nowrap"
            >Publish Sekarang</span>
            <b-spinner
              v-if="loading.publish"
              small
            />
            <i
              v-else
              class="icon k-document-upload text-[24px]"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DAY_MONTH_YEAR } from '@/libs/filterDate'

export default {
  props: {
    loading: {
      type: Object,
      default: () => ({
        all: false,
        image: false,
        body: false,
        publish: false,
      }),
    },
    preview: {
      type: Object,
      default: () => ({
        visibility: 'public',
        title: '',
        body: '',
      }),
    },
    meta: {
      type: Object,
      default: () => ({
        image: 'https://storage.googleapis.com/komerce/assets/Default-Photo-Web.png',
        updated_at: '',
        published_at: null,
      }),
    },
  },
  data() {
    return {
      DAY_MONTH_YEAR,
    }
  },
  computed: {
    isDisable() {
      return this.meta.published_at || this.loading.publish
    },
  },
}
</script>
