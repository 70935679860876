<template>
  <b-modal
    id="edit-news-modal"
    hide-footer
    hide-header
    centered
    scrollable
    dialog-class="!w-[calc(100%-5vw)] !max-w-[860px]"
    @hidden="!isCancel || $emit('close')"
  >
    <div
      class="text-[#333333] sm:px-[19px] sm:py-[33px] flex flex-col justify-center gap-[24px]
      h-[calc(100vh-10vh)] max-h-[600px]"
    >
      <div class="flex items-center gap-[16px] justify-between flex-wrap">
        <b class="text-[20px] text-[#222222] whitespace-nowrap">Edit Berita</b>
        <div class="flex justify-end grow">
          <Visibility
            :access="model.visibility"
            :access-option="accessOption"
            @update:access="visibility => $emit('update:model', {...model, visibility})"
          />
        </div>
      </div>
      <div class="flex flex-col justify-between gap-[24px] h-[calc(100%-8.25rem)] sm:h-[calc(100%-5.25rem)]">
        <div class="border-t border-[#E2E2E2] pt-[24px] h-[calc(100%-7.5rem)] sm:h-[calc(100%-8rem)]">
          <input
            type="text"
            name="news-title"
            class="text-[17px] sm:text-[20px] font-semibold outline-none border border-[#E2E2E2] rounded-lg py-[8px] px-[12px] w-full"
            spellcheck="false"
            autocomplete="new-password"
            :value="model.title"
            @input="onTitleChange"
            @keypress="onTitleKeypress"
            @paste="onTitlePaste"
          >
          <quill-editor
            ref="quillNews"
            :content="model.body"
            :options="editorOptions"
            spellcheck="false"
            class="mt-[16px] [&_p]:text-[16px] [&_p]:font-poppins"
            @change="onContentChange"
            @paste.native="onContentPaste"
            @keypress.native="onContentKeypress"
            @ready="onContentReady"
          />
          <span class="text-right text-[12px] w-full block -translate-y-[22px]">{{ currentContentLength }}/{{ maxLength.content }} karakter</span>
        </div>
        <div class="flex gap-y-[8px] gap-x-[12px] flex-wrap [&>*]:grow [&>*]:basis-[2.5rem] [&>*]:whitespace-nowrap">
          <b-button
            variant="outline-primary"
            @click="onClose"
          >
            Batalkan
          </b-button>
          <b-button
            variant="primary"
            :disabled="isSameWithExisting"
            @click="onSave"
          >
            Simpan Perubahan
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import Visibility from './Visibility.vue'

export default {
  components: {
    quillEditor,
    Visibility,
  },
  props: {
    accessOption: {
      type: Array,
      default: () => [
        { value: 'public', label: 'Public', icon: 'k-global' },
        { value: 'partner', label: 'Partner Terasign', icon: 'k-people' },
      ],
    },
    model: {
      type: Object,
      default: () => ({
        visibility: '',
        title: '',
        body: '',
      }),
    },
    existing: {
      type: Object,
      default: () => ({
        visibility: '',
        title: '',
        body: '',
      }),
    },
  },
  data() {
    return {
      maxLength: {
        title: 100,
        content: 2000,
      },
      currentContentLength: 0,
      isCancel: true,
      lastPasteTime: 0,
      pasteDelay: 150,
      editorOptions: {
        direction: 'ltr',
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline'], [{ align: '' }, { align: 'center' }, { align: 'right' }]],
          },
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                handler: () => {
                  if (this.currentContentLength >= this.maxLength.content) {
                    return false
                  }
                  return true
                },
              },
              v: {
                key: 86,
                handler: () => {
                  if (this.currentContentLength >= this.maxLength.content) {
                    return false
                  }
                  return true
                },
              },
            },
          },
        },
        placeholder: 'Masukkan teks ...',
      },
    }
  },
  computed: {
    isSameWithExisting() {
      return JSON.stringify(this.model) === JSON.stringify(this.existing)
    },
  },
  methods: {
    onSave() {
      this.isCancel = false
      this.$emit('save')
      this.$bvModal.hide('edit-news-modal')
      setTimeout(() => { this.isCancel = true }, 1000)
    },
    onClose() {
      this.$emit('close')
      this.$bvModal.hide('edit-news-modal')
    },
    onTitleChange(e) {
      this.$emit('update:model', { ...this.model, title: e.target.value })
    },
    onTitleKeypress(e) {
      if (this.model.title.length >= this.maxLength.title) e.preventDefault()
    },
    onTitlePaste(e) {
      e.preventDefault()
      const title = `${this.model.title}${e.clipboardData.getData('text/plain')}`.substring(0, 100)
      this.$emit('update:model', { ...this.model, title })
    },
    onContentChange({ html, text }) {
      this.currentContentLength = text.length - 1
      const { quill } = this.$refs.quillNews
      if (quill.getLength() > this.maxLength.content) {
        quill.deleteText(this.maxLength.content, quill.getLength())
        return
      }
      this.$emit('update:model', { ...this.model, body: html })
    },
    onContentPaste(e) {
      const currentTime = Date.now()
      if (currentTime - this.lastPasteTime < this.pasteDelay || this.currentContentLength === this.maxLength.content) {
        e.preventDefault()
        return
      }
      this.lastPasteTime = currentTime
    },
    onContentKeypress(e) {
      if (this.currentContentLength >= this.maxLength.content) e.preventDefault()
    },
    onContentReady(e) {
      this.currentContentLength = e.getLength() - 1
    },
  },
}
</script>

<style scoped>
@import '~quill/dist/quill.snow.css';

.quill-editor >>> p {
  font-family: 'Poppins', sans-serif;
  color: #333333;
  font-size: 14px;
  line-height: 21px;
}

.quill-editor >>> strong {
  font-weight: 600;
}

.quill-editor, .quill-editor >>> .ql-container {
  height: 100%;
}

.quill-editor >>> .ql-container {
  min-height: 3rem;
  max-height: calc(100% - 68px);
}

.quill-editor >>> .ql-editor {
  height: 100%;
  z-index: 0 !important;
}
.quill-editor >>> .ql-snow {
  border-color: #E2E2E2;
}

@media screen and (max-width: 390px) {
  .border-t {
    height: calc(100% - 10.5rem) !important;
  }
}

@media screen and (min-width: 640px) {
  .quill-editor >>> p {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
